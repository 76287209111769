var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "domain-ssl-certificate" } },
    [
      _c(
        "h4",
        [
          _c("span", [_vm._v("SSL Certificate")]),
          _vm.serviceIsExpiring
            ? _c(
                "b-btn",
                {
                  staticClass: "float-right",
                  attrs: { variant: "primary" },
                  on: { click: _vm.openConfirmWindow },
                },
                [_vm._v("\n      Reactivate Domain SSL\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.serviceIsExpiring
        ? _c("domain-service-pending-removal", {
            attrs: { service: _vm.service },
          })
        : _vm._e(),
      _vm._l(_vm.domainSsl, function (ssl) {
        return _c(
          "b-row",
          [
            _c("b-col", [
              _c("h5", { staticClass: "sub-title" }, [_vm._v("Status")]),
              _c(
                "span",
                {
                  class: `${
                    _vm.statusClassMap[ssl.status]
                  } ssl-certificate-status`,
                },
                [
                  _c("b-icon", {
                    attrs: { icon: _vm.statusIconMap[ssl.status] },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.capitalize(ssl.status)) +
                      "\n      "
                  ),
                ],
                1
              ),
            ]),
            _c(
              "b-col",
              [
                _c("h5", { staticClass: "sub-title" }, [_vm._v("Actions")]),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "mb-4" },
                      [
                        _c(
                          "b-btn",
                          {
                            attrs: {
                              variant: "primary",
                              disabled:
                                _vm.isRevalidatingCertificate ||
                                (ssl.status !== "incomplete" &&
                                  ssl.status !== "rejected"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.revalidateCertificate(ssl)
                              },
                            },
                          },
                          [
                            _c("b-icon-arrow-clockwise"),
                            _vm._v(" Revalidate\n          "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("b-col", { staticClass: "mb-4" }, [
                      _c(
                        "div",
                        { staticClass: "actions-section" },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                variant: "primary",
                                disabled:
                                  _vm.isDownloadingCertificate ||
                                  ssl.status !== "active" ||
                                  !ssl.cert_order_id,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadCertificate(ssl)
                                },
                              },
                            },
                            [
                              _c("b-icon-download"),
                              _vm._v(" Download\n            "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.showConfirmWindow
        ? _c("reactivate-domain-service-confirm-window", {
            attrs: {
              service: _vm.service,
              "is-deleting": _vm.serviceIsDeleting,
            },
            on: {
              hideConfirmWindow: _vm.hideConfirmWindow,
              changeInProcess: _vm.changeInProcess,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }